<template>
  <a-layout class="layout" style="height: 100vh">
    <a-form-model
      ref="loginForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="login-form"
    >
      <h1 style="margin-bottom: 20px">Klarity BI Dashboard - SignIn</h1>
      <!-- <a-form-model-item ref="username" label="用户名" prop="username">
        <a-input
          v-model="form.username"
          type="text"
          autocomplete="off"
          @keyup.enter="login"
        />
      </a-form-model-item> -->
      <a-form-model-item ref="password" label="Password" prop="password">
        <a-input
          v-model="form.password"
          type="password"
          autocomplete="off"
          @keyup.enter="login"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" style="width: 45%" @click="login">
          Sign In
        </a-button>
        <a-button style="margin-left: 30px; width: 45%" @click="reset">
          Reset
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-layout>
</template>

<script>
export default {
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        username: '',
        password: ''
      },
      rules: {
        // username: [
        //   {
        //     required: true,
        //     message: '请输入用户名',
        //     trigger: 'blur'
        //   }
        // ],
        password: [
          {
            required: true,
            message: 'You must provide a password',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    async login () {
      const validPasswords = ['SagaPixel', 'MegaPhone', 'KlarityOperation', 'sjx']
      const valid = await this.$refs.loginForm.validate()
      if (!valid) return
      if (validPasswords.indexOf(this.form.password) === -1) {
        return this.$message.error('Authentication failed')
      }
      // 登录成功
      this.$message.success('Authentication success')
      this.$store.commit('setAuthorized', true)
      this.$router.push(this.$route.query.redirect || '/practitioner/appointment/summary')
    },
    reset () {
      this.$refs.loginForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  //background: url("assets/login-background-1.jpg");
  background-size: 100% 100%;
}

.login-form {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 600px;
  position: relative;
  margin: 0 auto;
  padding: 40px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
